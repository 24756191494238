export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      if (response.status !== 200) {
        reject(response);
      }
      response.json().then(function (data) {
        resolve(data);
      });
    }).catch(function (err) {
      reject(err);
    });
  });
};
const API_ROOT = process.env.REACT_APP_API_ROOT;
function queryParams(params) {
  return Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
}
export const getCategories = ({
  app
}) => {
  return get(`${API_ROOT ? API_ROOT : ''}/api/${app}/categories/`);
};
export const getData = ({
  source,
  app,
  params
}) => {
  return get(`${API_ROOT ? API_ROOT : ''}/api/${app}/stats/${source}${params ? '?' + queryParams(params) : ''}`);
};