import Resources from './embedded/resources/index';
import Reducers from './embedded/reducers/index';
import ResponsiveContainer from './layout';
const components = {
  resources: Resources
};
const getComponentByNameIgnoreCase = name => {
  const k = Object.keys(components).filter(value => value.toLowerCase() == name.toLowerCase());
  return components[k];
};
export { getComponentByNameIgnoreCase, Reducers, ResponsiveContainer };